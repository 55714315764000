import Vue from 'vue'
import axios from 'axios'
import router from '../router'
import store from '../store'
import validation from '../utils/validation'

/**
 * 提示函数
 */
const v = new Vue()
const tip = msg => {
  v.$Toast(msg)
}
/**
 * 跳转登录页
 * */
const toLogin = () => {
  router.replace({
    path: '/chooseLogin',
    query: {
      redirect: router.currentRoute.fullPath
    }
  })
}
/**
 * 请求失败后的错误统一处理
 * @param {Number} status 请求失败的状态码
 */
const errorHandle = (status, other) => {
  switch (status) {
    case 401:
      toLogin()
      break
    case 403:
      tip('登录过期，请重新登录')
      sessionStorage.removeItem('token')
      store.commit('loginSuccess', null)
      setTimeout(() => {
        toLogin()
      }, 1000)
      break
    case 404:
      tip('请求的资源不存在')
      break
    default:
      console.log(other)
  }
}

function checkCode (response) {
  if (response.code === '0') {
    return response
  } else {
    const m = validation.langText(sessionStorage.getItem('langType') || 'fr').message
    v.$Toast(response[m])
    return false
  }
}

const instance = axios.create({ timeout: 1000 * 20 })

instance.defaults.headers.post['Content-Type'] = 'application/json'

/**
 * 请求拦截器
 * 每次请求前，如果存在token则在请求头中携带token
 */
instance.interceptors.request.use(
  config => {
    config.headers.Authorization = sessionStorage.getItem('token' || '')
    store.state.isLoading = true
    return config
  },
  error => Promise.error(error))

// 响应拦截器
instance.interceptors.response.use(
  // 请求成功
  res => {
    // res.status === 200 ? Promise.resolve(res) : Promise.reject(res)
    store.state.isLoading = false
    return res.data
  },
  // 请求失败
  error => {
    const { response } = error
    store.state.isLoading = false
    if (response) {
      errorHandle(response.status, response.data.message)
      return Promise.reject(response)
    } else {
      tip('请求资源不存在！')
      return Promise.reject(error)
    }
  })

export default function request (method, url, data) { // 暴露 request 给我们好API 管理
  method = method.toLocaleLowerCase()
  if (method === 'post') {
    return instance.post(url, data).then((response) => {
      return checkCode(response)
    })
  } else if (method === 'get') {
    data = {
      timestamp: Date.now(),
      ...data
    }
    return instance.get(url, {
      params: data
    }).then((response) => {
      return checkCode(response)
    })
  }
}
