import Vue from 'vue'
import { Popup, Field, Toast, Loading, Lazyload, DropdownMenu, DropdownItem, PullRefresh } from 'vant'
import 'vant/lib/index.css'

Vue.use(Popup)
Vue.use(Field)
Vue.use(Lazyload)
Vue.use(Loading)
Vue.use(DropdownMenu)
Vue.use(DropdownItem)
Vue.use(PullRefresh)
Vue.prototype.$Toast = Toast
