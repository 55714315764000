/**
 * 游戏模块接口
 **/
import base from '../base'
import request from '../http'

export default {
  getPrizeList: promise => request('get', `${base.api.url}/api/v1/swiftspin/prize/list`, promise), // 奖品列表
  getAd: promise => request('get', `${base.api.url}/api/v1/swiftspin/ad`, promise), // 获取广告
  getPrizeRewardCode: (rewardCode, promise) => request('get', `${base.api.url}/api/v1/swiftspin/prize/${rewardCode}`, promise), // 抽奖
  getInfoCode: (rewardCode, promise) => request('get', `${base.api.url}/api/v1/swiftspin/info/${rewardCode}`, promise), // 判断是否中奖
  submitInfo: promise => request('post', `${base.api.url}/api/v1/swiftspin/submit/info`, promise) // 提交信息
}
